import React, {useReducer} from 'react'
import OperationButton from './OperationButton';
import './App.css';
import "./styles.css"
import DigitButton from './DigitButton';

export const ACTIONS = {
  ADD_DIGIT: 'add-digit',
  CLEAR:'clear',
  DELETE_DIGIT: 'delete-digit',
  CHOOSE_OPERATION: 'choose-operation',
  EVALUATE:'evaluate'

}

function reducer(state, {type, payload}){
  switch (type){
    case ACTIONS.ADD_DIGIT:
      if(state.overwrite){
        return {
          ...state,
          currentOperand: payload.digit,
          overwrite: false,
        }
      }
      if(payload.digit==="0" && state.currentOperand==="0")
       {return state}
      if(payload.digit==="." && state.currentOperand.includes("."))
       {
        return state
      }
      return {
        ...state,
        currentOperand:  `${state.currentOperand|| ""} ${payload.digit}`,
      }
      case ACTIONS.CHOOSE_OPERATION:
      if(state.currentOperand==null && state.previousOperand==null){
        return state
      }

      if(state.currentOperand==null){
        return{  
        ...state,
        operation: payload.operation,
      }
      }
      if (state.previousOperand==null){
        return{
          ...state,
          operation: payload.operation,
          previousOperand: state.currentOperand,
          currentOperand :null,
        }
      }
      return{
        ...state,
        previousOperand: evaluate(state),
        operation: payload.operation,
        currentOperand: null,
      }
       case ACTIONS.CLEAR:
        return {}

       case ACTIONS.DELETE_DIGIT:
          if(state.overwrite) {
            return {
              ...state,
              overwrite:false,
              currentOperand :null,
            }
          }
          if(state.currentOperand==null) return state
          if (state.currentOperand.length===1){
            return {...state, currentOperand: null}
          }
          return{
            ...state, 
            currentOperand: state.currentOperand.slice(0, -1)
          }
        case ACTIONS.EVALUATE:
         

          if(payload==="sin"||payload==="cos"||payload==="sqrt"||payload==="tan"){
            if(state.currentOperand==null){
              return state
            }

            return{
              ...state,
              overwrite:true,
              previousOperand:null,
              operation: null,
              currentOperand: evaluate({currentOperand:state.currentOperand,previousOperand:state.currentOperand,operation:payload})
            }
            
          }
         
        
          
          if(state.operation ==null || state.currentOperand==null || state.previousOperand==null){
            return state
          }
          
          return{
            ...state,
            overwrite: true,
            previousOperand: null,
            operation: null,
            currentOperand: evaluate(state),
          }
  }
}

    function evaluate({currentOperand, previousOperand , operation}){
      const currentOperandNoSpaces=currentOperand.split(' ').join('');
      const previousOperandNoSpaces=previousOperand.split(' ').join('');
      const prev= parseFloat(previousOperandNoSpaces);
      const curr= parseFloat(currentOperandNoSpaces);
      if (isNaN(prev) || isNaN(curr)) return"";
      let computation = "";

      switch(operation){
        case "+":
         computation =  prev+curr
         break;
        case "-":
          computation = prev-curr
          break;
        case "*":
          computation = prev*curr
          break
        case "/":
          computation = prev/curr
          break;
        case "sin":
          computation = Math.sin(curr)
          break;
        case "cos":
          computation = Math.cos(curr)
          break; 
        case "tan":
          computation = Math.tan(curr)
          break;  
        case "sqrt":
          computation = Math.sqrt(curr)
          break;                         
      }
        return computation.toString();
    }

function App() {

  const[{currentOperand, previousOperand, operation},dispatch]= useReducer(
    reducer, 
    {})
  
 return(
   
   <div className ="calculator-grid">
   
   <div className ="output">
   <div className="previous-operand">
    {previousOperand}{operation}
    </div>
   <div className="current-operand">{currentOperand}</div>
      </div>
    <button className="span-two" 
    onClick={()=>dispatch({type: ACTIONS.CLEAR})}>
      AC</button>
    <button onClick={()=>dispatch({type: ACTIONS.DELETE_DIGIT})}>DEL</button>
    <OperationButton operation="/" dispatch={dispatch}/>
    <DigitButton digit="1" dispatch={dispatch}/>
    <DigitButton digit="2" dispatch={dispatch}/>
    <DigitButton digit="3" dispatch={dispatch}/>
    <OperationButton operation="*" dispatch={dispatch}/>
    <DigitButton digit="4" dispatch={dispatch}/>
    <DigitButton digit="5" dispatch={dispatch}/>
    <DigitButton digit="6" dispatch={dispatch}/>
    <OperationButton operation="+" dispatch={dispatch}/>
    <DigitButton digit="7" dispatch={dispatch}/>
    <DigitButton digit="8" dispatch={dispatch}/>
    <DigitButton digit="9" dispatch={dispatch}/>
    <OperationButton operation="-" dispatch={dispatch}/>
    <DigitButton digit="." dispatch={dispatch}/>
    <DigitButton digit="0" dispatch={dispatch}/>
    <button className="span-two" onClick={()=>dispatch({type: ACTIONS.EVALUATE})}>=</button>
    <button onClick={()=>dispatch({type: ACTIONS.EVALUATE, payload:"sin"})}>SIN</button>
    <button onClick={()=>dispatch({type: ACTIONS.EVALUATE, payload:"cos"})}>COS</button>
    <button onClick={()=>dispatch({type: ACTIONS.EVALUATE, payload:"tan"})}>TAN</button>
    <button onClick={()=>dispatch({type: ACTIONS.EVALUATE, payload:"sqrt"})}>SQRT</button>
    <span>source: https://github.com/WebDevSimplified/react-calculator</span>
  </div>
   

 );
}

export default App;
